/* eslint-disable camelcase, no-console */
import {
	type UserProfile as Profile,
	User,
	UserManager,
	type UserManagerSettings,
	WebStorageStateStore,
	InMemoryWebStorage,
} from "oidc-client-ts";
import join from "lodash/fp/join";

import { mapUserProfile } from "./userProfile";
import { config } from "../../config";
import type { OAuthConfig } from "..";
import type { AccessToken } from "../tokenHandling/tokenSlice";
import type { UserProfile } from "./loginSlice";

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (oauthConfig: OAuthConfig, userManager: UserManager) => {
	userManager.signinSilent().catch((error: Error) => {
		if (error.message === "login_required") {
			oauthConfig.onSessionExpired();
		} else {
			setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
		}
	});
};

export type SessionRenewedResult = {
	accessToken: AccessToken;
	idToken: Profile;
	profile: UserProfile;
	locale: string;
};

export const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
	accessToken: result.access_token,
	idToken: result.profile,
	locale: result.profile?.locale ?? "en-GB",
	profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
	const redirectUri = config.login.redirectUri;
	const silentRedirectUri = config.login.silentRedirectUri;

	const settings: UserManagerSettings = {
		authority: `${config.login.authority}`,
		client_id: `${config.login.clientId}`,
		loadUserInfo: false,
		redirect_uri: `${redirectUri}`,
		response_type: "code",
		scope: join(" ", config.login.oauthScope),
		silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
		includeIdTokenInSilentRenew: false,
		automaticSilentRenew: true,
		monitorSession: true,
		staleStateAgeInSeconds: 600,
		userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
		filterProtocolClaims: false,
	};

	return new UserManager(settings);
};

export const configureUserManager = (oauthConfig: OAuthConfig, userManager: UserManager) => {
	userManager.events.addUserLoaded((user) => {
		oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
	});

	userManager.events.addUserUnloaded(() => {
		oauthConfig.onSessionExpired();
	});

	userManager.events.addSilentRenewError(() => {
		retrySigninSilent(oauthConfig, userManager);
	});

	userManager.events.addUserSignedOut(() => {
		oauthConfig.onSessionExpired();
	});

	return userManager;
};

export const configureMockUserManager = (oauthConfig: OAuthConfig): UserManager => {
	const signinSilent = () => {
		const userSettings = {
			// manual token
			access_token:
				"eyJraWQiOiJjMDNmNDMwMy05OTk4LTRkYmItYjgxNC03OGI0MmIwNmE0NDkiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJwcm9kLXJpby11c2VyczphOGJiMTQwZS05MDNmLTQ5MDQtYmUyMi1hN2UxYTUwNmZiZWMiLCJhenAiOiJhNmExMjlkMi02YTc3LTQyMDYtOWE1NS0wYjM3YmNiMDQyMDkiLCJzY29wZSI6ImFzc2V0LWFkbWluaXN0cmF0aW9uLnJlYWQgcGhvbmUgb3BlbmlkIHByb2ZpbGUgdGFncy5yZWFkIGVtYWlsIiwiaXNzIjoiaHR0cHM6Ly9hdXRoLmlhbS5yaW8uY2xvdWQiLCJleHAiOjE3NDI4MzU5NjIsImlhdCI6MTc0MjgzMjM2MiwianRpIjoiMDlkMjQ2NDktOTVhNC00ZDhiLWEyYzYtNTdlYmU2YTgwYzE3IiwiY2xpZW50X2lkIjoiYTZhMTI5ZDItNmE3Ny00MjA2LTlhNTUtMGIzN2JjYjA0MjA5IiwiYWNjb3VudCI6ImIxY2JlOWRlLWJlODEtNGM2Ny05YThmLWJkY2JjNzgyYWI1MCIsInRlbmFudCI6InJpby1icmF6aWwucHJvZCJ9.bK8Ir7Dbz88s7MCbgiUhkl_uc7v2ld4EIzwGPudrVzsTpeUPMhqW4XuEXjFexSbJmdqTCAUGUThGc4t7awBZqUSFgquoRmO-dxdoG4rYECeyqul0GSX0SSsPrhWEhhqReKkTbd2HG5y6KeIcol0f0I9HWdZPuxb2HupGXsusa9k2lmlpDgB3M6CoHegPH4yCMQOLuNaJdc000Oz_nEayhtm1THNnNpJCEpa32-QQOIYRPhx4pCabYiU0Isfy4YnDPsMibNhnoJUWzCUKEvMjbXfdHMcQHwNm7yGzMcvXYzu5hicDDK1jV-FLgzckWQjNaNnZrUy7ZIUxb7Ap1q0o3nL3d8r_zFlxVWCN3dTRTsyz6_1URmHpJzbGpWJYyPjHT0A-fDC2GgPYCMzEWtQ7xdKFt8XTAqFZFBub2DzDMGWXRbHuGzGyyfigLZzE42KBHdCLrhMuJu0hLWMKgxCy5Luzxy2VdFpLJdGBcbtlBTiD5hEsXkHUt79c9jkZG2mZfHSJZnl3bLPt3YNwO33yEiGgURcDpLjTnV6nBRzS9Kc8r2CXOdFcfp3kZJkEoPuoe_dez64Vux1LsS53iE2WDKF_bhrOEWkcxdazuXfjp5KGeeyBuEMiSpJzHFxXzTEjNG44tPPZyywB0IeTyFjn9x55EKNrWegRRPiwFEMbb4w",
			profile: {
				iss: "Issuer Identifier",
				aud: "Audience(s): client_id",
				exp: 10,
				iat: 5,
				account: "local", // mock possible account ids here
				azp: "test-client",
				email: "test@example.com",
				family_name: "Client",
				given_name: "Test",
				name: "Test Client",
				sub: "prod-rio-users:mock-user",
				locale: config.login.mockLocale,
				tenant: config.login.mockTenant,
			},
			id_token: "id_token",
			session_state: "session_state",
			refresh_token: "refresh_token",
			token_type: "token_type",
			scope: "scope",
			expires_at: 10000000,
			state: "state",
		};

		const user = new User(userSettings);
		oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
		return Promise.resolve(user);
	};

	const clearStaleState = () => {
		console.info("[configuration/login/oidc-session] Stale state cleared");
		return Promise.resolve();
	};

	return { signinSilent, clearStaleState } as UserManager;
};
